/* eslint-disable max-len */
export default {
  'Our Conferences': 'Nuestras Conferencias',
  'Network Error': 'Error de conexión',
  Conferences: 'Conferencias',
  Export: 'Exportar',
  Settings: 'Ajustes',
  Logout: 'Cerrar Sesión',
  'Generate Report': 'Generar Reporte',
  'CSV Delimiter': 'Delimitador CSV',
  'What delimiter does the uploaded file use?': 'Que delimitador utiliza el archivo cargado?',
  Delimiter: 'Delimitador',
  Institute: 'Instituto',
  Login: 'Iniciar Sesión',
  English: 'Inglés',
  Spanish: 'Español',
  Conference: 'Conferencia',
  'CSV Export': 'Exportación CSV',
  "To print the tags you must upload the file to Andreani's portal.": 'Para imprimir las etiquetas debe subir el archivo al portal de Andreni.',
  Search: 'Buscar',
  Reset: 'Reiniciar',
  'Delimiter Required': 'Delimitador Requerido',
  'Start Date': 'Fecha desde',
  'Enter a type': 'Ingrese un tipo',
  'End Date': 'Fecha hasta',
  'New Conference': 'Nueva Conferencia',
  'Create it': 'Crear',
  'Number of': 'Número de',
  'Rows per page:': 'Filas por página:',
  Orders: 'Órdenes',
  Name: 'Nombre',
  Source: 'Origen',
  State: 'Estado',
  Order: 'Orden',
  Pulled: 'Retirado',
  Match: 'Partido',
  Close: 'Cerrar',
  'State Name': 'Nombre de la provincia',
  'City Name': 'Nombre de la localidad',
  'City Information': 'Información de la localidad',
  'State Information': 'Información provincial',
  'City Matchs': 'Partidos de la provincia',
  'State Matchs': 'Coincidencias provinciales',
  'Cities List': 'Listado de Localidades',
  Cities: 'Ciudades',
  Evaluated: 'Evaluada',
  'City Detail': 'Detalle de la localidad',
  OK: 'OK',
  Province: 'Provincia',
  'Province Id': 'Id de Provincia',
  Problem: 'Con Problemas',
  'Manually Fixed': 'Corregida manualmente',
  Provinces: 'Provincias',
  Created: 'Creado',
  Sold: 'Vendido',
  'On queue': 'En cola',
  'Number of Documents': 'Cantidad de documentos',
  'Number of Items': 'Cantidad de items',
  'Waiting for preparation': 'Esperando Preparación',
  Prepared: 'Preparado',
  Documents: 'Documentos',
  'Ready for delivery': 'Listo para la entrega',
  'Out for delivery': 'En camino',
  Delivered: 'Entregado',
  Cancelled: 'Cancelado',
  'Do you want to accept the order?': '¿Quieres aceptar la orden?',
  'Reject Order': 'Rechazar Orden',
  'Do you want to reject the order?': '¿Quieres rechazar la orden?',
  'Do you want to process the import?': '¿Quieres procesar la importación?',
  Date: 'Fecha',
  Participants: 'Participantes',
  'Delete File': 'Borrar archivo',
  'Do you want to delete this file?': '¿Quieres borrar este archivo?',
  Description: 'Descripción',
  'Person to visit': 'Persona a visitar',
  'Please fill out this field.': 'Por favor complete este campo.',
  'The value must be a positive number': 'El valor debe ser un número positivo',
  'Edit Conference': 'Editar Conferencia',
  'Save it': 'Guardar',
  'Sign in': 'Entrar',
  'Sign In': 'Entrar',
  "Don't have an account? Sign Up": '¿No tenés una cuenta? Registrate',
  'Already have an account? Sign in': '¿Ya tenés una cuenta? Inicia Sesión',
  'Sign up': 'Registrar',
  'Sign Up': 'Registrar',
  Enroll: 'Inscribirse',
  'Do you want to enroll to': '¿Querés inscribirte a',
  Profile: 'Perfil',
  'Request failed with status code 404': 'Falló de Petición: 404.',
  'Request failed with status code 400': 'Falló de Petición: 400.',
  Email: 'Correo Electrónico',
  Password: 'Contraseña',
  'The provided authorization grant (e.g., authorization code, resource owner credentials) or refresh token is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.':
    'Credenciales Inválidas',
  'Available positions': 'Cupo disponible',
  'Signed Users': 'Usuarios Inscriptos',
  'First Name': 'Nombre',
  'Saved files': 'Archivos guardados',
  'Updated status': 'Estado actualizado',
  'Deleted file': 'Archivo borrado',
  'Import created': 'Importación creada',
  'Last Name': 'Apellido',
  'Repeat Password': 'Repita la Contraseña',
  'Passwords must match!': 'Las Contraseñas deben coincidir!',
  'The email has already been taken.': 'El Correo Electrónico ingresado ya existe.',
  'The value must be a valid email': 'El valor debe ser un Correo Electrónico válido',
  'Remove Confirmation': 'Confirmación de Eliminación',
  'Do you want to remove': '¿Querés eliminar',
  'Remove it': 'Eliminar',
  No: 'No',
  Saved: 'Guardado',
  'SQLSTATE[22P02]: Invalid text representation: 7 ERROR:  invalid input syntax for integer: "new" (SQL: select * from "conferences" where "id" = new limit 1)':
    'Se produjo un error SQLSTATE[22P02] al generar o editar un registro. Posiblemente haya un dato vacío o mal ingresado. Por favor, vuelva a intentar ingresarlo en unos minutos.',
  'SQLSTATE[22P02]: Invalid text representation: 7 ERROR:  invalid input syntax for integer: "[object Object]" (SQL: select * from "conferences" where "id" = [object Object] limit 1)':
    'Se produjo un error SQLSTATE[22P02] al editar un registro. El objeto que se quería modificar no existe o no se encuentra. Posiblemente haya sido dado de baja o cambiado de estado. Por favor, vuelva a intentarlo en unos minutos.',
  'Permission denied!': '¡Permiso Denegado!',
  'Sign Up Confirmation': 'Confirmación de Inscripción',
  'Do you want to sign up to': '¿Querés inscribirte a',
  'Unsubscribe Confirmation': 'Confirmación de Desinscripción',
  'Do you want to unsubscribe': '¿Querés desinscribirte de',
  Unsubscribe: 'Desinscribir',
  'Unauthenticated.': 'No autenticado.',
  'SQLSTATE[22P02]: Invalid text representation: 7 ERROR:  invalid input syntax for integer: "undefined" (SQL: select * from "conferences" where "id" = undefined limit 1)':
    'Se produjo el error SQLSTATE[22P02]. El dato no se ingresó correctamente y no es posible autentificarse. Por favor, vuelva a ingresarlo en unos minutos.',
  'Expired conference': 'Conferencia expirada',
  'Do you want to remove your inscription for': '¿Querés desinscribirte de ',
  'No query results for model [App\\Conference].': 'No hay datos para mostrar',
  'Upload File': 'Subir Archivo',
  'Select the source': 'Seleccione la fuente',
  'The name field is required.': 'El campo Nombre es requerido.',
  'This conference already has full places': 'La conferencia no tiene plazas disponibles',
  'Visits List': 'Listado de Visitas',
  'People List': 'Listado de Personas',
  'No match': 'Sin coincidencias',
  Loading: 'Cargando',
  People: 'Personas',
  Visits: 'Visitas',
  Username: 'Nombre de usuario',
  User: 'Usuario',
  Type: 'Tipo',
  'Invalid user': 'Usuario inválido',
  'Philsen App': 'Aplicación Philsen',
  'New Visit': 'Nueva Visita',
  Document: 'Documento',
  Restore: 'Restaurar',
  Scan: 'Escanear',
  Genre: 'Genero',
  'Birth Date': 'Fecha de Nacimiento',
  'Card Number': 'Número de Tarjeta',
  Cancel: 'Cancelar',
  Save: 'Guardar',
  Female: 'Femenino',
  Male: 'Masculino',
  All: 'Todos',
  'Invalid Date': 'Fecha Inválida',
  'Date should not be before minimal date': 'La fecha no debe ser anterior a la fecha mínima',
  'Date should not be after maximal date': 'La fecha no debe ser posterior a la fecha máxima',
  'Something is wrong': 'Algo salió mal',
  'Please try again later': 'Por favor vuelva a intentarlo más tarde',
  'Visit saved': 'Visita guardada satisfactoriamente',
  'Searching Person': 'Buscando persona',
  Yes: 'Si',
  'Confirm new import': 'Confirmar nueva importación',
  'Change Password': 'Cambiar Contraseña',
  'Export Orders': 'Exportar Órdenes',
  'New external data will be imported into the system, are you sure to proceed with type $$$$?':
    'Se importaran nuevos datos externos al sistema.¿Está seguro de proceder con el tipo $$$$?',
  'Do you want to change the account password of the user $$$$?': '¿Quieres cambiar la contraseña del usuario $$$$?',
  'Do you want to change the account role of the user $$$$?': '¿Quieres cambiar el rol del usuario $$$$?',
  'Do you want to change the account name of the user $$$$?': '¿Quieres cambiar el nombre del usuario $$$$?',
  'Do you want to disable the account of the user $$$$?': '¿Quieres deshabilitar al usuario $$$$?',
  'Do you want to enable the account of the user $$$$?': '¿Quieres habilitar al usuario $$$$?',
  Role: 'Rol',
  Administrator: 'Administrador',
  'No Role': 'Sin rol',
  Disable: 'Deshabilitar',
  Enable: 'Habilitar',
  Enabled: 'Habilitado',
  Disabled: 'Deshabilitado',
  Accounts: 'Cuentas',
  Status: 'Estado',
  Create: 'Crear',
  'Creation Day': 'Fecha de Creación',
  'Import Type': 'Tipo de Importación',
  'Import type selection': 'Selección de tipo de importación',
  'Select the type of the new import': 'Seleccione el tipo de la nueva importación',
  'Enable Account': 'Habilitar Cuenta',
  'Disable Account': 'Deshabilitar Cuenta',
  'Search User': 'Buscar usuario',
  'Check Availability': 'Verificar Disponibilidad',
  'El usuario ya existe': 'User already exists',
  'Create Account': 'Crear Cuenta',
  'No coincidences': 'Sin coincidencias',
  'Create Import': 'Crear Importación',
  'Imports List': 'Listado de Importaciones',
  'Orders List': 'Listado de Órdenes',
  'Inbox Orders List': 'Listado de Órdenes de Entrada',
  'Orders Entry': 'Entrada de Órdenes',
  'Inbox Order State ID': 'ID de Estado de Orden de Entrada',
  'Inbox Order Detail': 'Detalle de Orden de Entrada',
  Inbox: 'Entradas',
  Reference: 'Referencia',
  Imports: 'Importaciones',
  Import: 'Importación',
  'File deleted successfully': 'Archivo eliminado exitoxamente',
  'Import Detail': 'Detalle de Importación',
  'Updated Password': 'Contraseña Actualizada',
  'Updated Username': 'Nombre de Usuario Actualizado',
  'Updated Role': 'Rol Actualizado',
  'Disabled User': 'Usuario Deshabilitado',
  'Upload Files': 'Cargar Archivos',
  'Process Import': 'Procesar importación',
  Detail: 'Detalle',
  Accept: 'Aceptar',
  Owner: 'Propietario',
  'Failed to delete': 'No se pudo borrar',
  'Uploaded Files': 'Archivos cargados',
  'Save Files': 'Guardar Archivos',
  'Pending Processing': 'Procesamiento pendiente',
  Pending: 'Pendiente',
  Processing: 'Procesando',
  'Pending approbation': 'Pendiente de aprobación',
  Accepted: 'Aceptado',
  Rejected: 'Rechazado',
  Failure: 'Error',
  Example: 'Ejemplo',
  Example2: 'Ejemplo2',
  'Last Update': 'Última actualización',
  'Cannot upload file, reason:': 'No se pudo subir el archivo, razon:',
  'Marketplace ID': 'Id del Marketplace',
  Amount: 'Monto',
  Tax: 'Impuesto',
  Exit: 'Salir',
  Total: 'Total',
  created: 'Creado',
  selled: 'Vendido',
  prepared: 'Preparado',
  'waiting for preparation': 'Esperando por preparación',
  onqueue: 'En Cola',
  'ready for delivery': 'Listo para Entregar',
  'out for delivery': 'Enviado',
  delivered: 'Entregado',
  cancelled: 'Cancelado',
  'Order Detail': 'Detalle de la Órden',
  'Basic Information': 'Información Básica',
  Buyer: 'Comprador',
  'Document Type': 'Tipo de Documento',
  'Phone Number': 'Número de Teléfono',
  Address: 'Dirección',
  'Zip Code': 'Código Postal',
  'Shipment Cost': 'Costo de envío',
  Ster: 'Ster',
  Marketplace: 'Marketplace',
  Shipment: 'Envío',
  Service: 'Servicio',
  Courier: 'Mensajería',
  'Shipment Address': 'Dirección de envío',
  Comments: 'Comentarios',
  Receiver: 'Receptor',
  Tracking: 'Seguimiento',
  Items: 'Items',
  SKU: 'SKU',
  Zip: 'Código Postal',
  Quantity: 'Cantidad',
  Price: 'Precio',
  Subtotal: 'Subtotal',
  Number: 'Numero',
  Location: 'Localidad',
  'Creation Date': 'Fecha de Creación',
  Link: 'Vínculo',
  'Owner ID': 'ID del Propietario',
  'Go to file': 'Ir al archivo',
  Phone: 'Teléfono',
  Actions: 'Acciones',
  'Made in': 'Hecho en',
  action: 'acción',
  Action: 'Acción',
  Where: 'Dónde',
  'Track Information': 'Información de rastreo',
  'Affiliate ID': 'ID de afiliado',
  'Authorized Date': 'Fecha de autorización',
  'Client ID': 'ID del cliente',
  'Client email': 'Email del cliente',
  'Client phone': 'Teléfono del cliente',
  'Follow-up Email': 'Email de seguimiento',
  'Checked-in': 'Chequeo de Ingreso',
  Completed: 'Completado',
  'Marketplace URL': 'URL del Marketplace',
  'Status description': 'Descripción del estado',
  'Generated Order Detail': 'Detalles de la Orden Generada',
  'The edition will be mark this record to be reprocessed. Do you want to proceed?':
    'La edición marcará este registro para ser reprocesado. ¿Quiere proceder?',
  Proceed: 'Proceder',
  'Edition Confirmation': 'Confirmación de Edición',
  ID: 'ID',
  'Import type': 'Tipo de Importación',
  'Market place': 'Marketplace',
  'Owner id': 'ID del Propietario',
  Sku: 'Código Sku',
  Results: 'Resultados',
  Row: 'Fila',
  Message: 'Mensaje',
  Success: 'Exitosos',
  Fails: 'Fallidos',
  Errors: 'Errores',
  'Order ID': 'Id de orden',
  'Other fields': 'Otros filtros',
  'Import ID': 'Id de importación',
  Metrics: 'Métrica',
  'Order Imports': 'Importaciones de Órdenes',
  Clean: 'Limpiar',
  'Inbox ID': 'ID de orden',
  'City ID': 'ID de ciudad',
  'State ID': 'ID de provincia',
  'Location ID': 'ID de Localidad',
  'Province ID': 'ID de Provincia',
  'NOTIFY MARKETPLACE': 'NOTIFICAR MARKETPLACE',
  'PERSIST ORDER': 'PERSISTIR ORDEN',
  'PROCESS ITEMS': 'PROCESAR ÍTEM',
  'LOOK FOR STATUS': 'BUSCAR POR ESTADO',
  'LOOK FOR COURIER': 'BUSCA POR CORREO',
  'LOCATION:LOOK FOR CITY': 'UBICACIÓN: BUSCAR POR LOCALIDAD',
  'LOCATION:LOOK FOR PROVINCE': 'UBICACIÓN: BUSCAR POR PROVINCIA',
  'PROCESS SHIPMENT': 'PROCESAR ENVIO',
  'LOOK FOR DOCUMENT TYPE': 'BUSCAR POR TIPO DE DOCUMENTO',
  'PROCESS BUYER': 'PROCESAR COMPRADOR',
  'LOOK FOR SOURCE': 'BUSCAR POR FUENTE',
  'GRAB DATA FROM SERVER': 'OBTENER DATOS DEL SERVIDOR',
  'start-handling': 'iniciar-manejo',
  ORDER: 'ÓRDEN',
  SHIPPING: 'ENVIO',
  BUYER: 'COMPRADOR',
  'Request user': 'Solicitar usuario',
  'Please wait for Admin approval': 'Espere a que el administrador apruebe',
  'Account ID': 'ID de la cuenta',
  'Accounts List': 'Listado de Cuentas',
  Locations: 'Localidades',
  Shipments: 'Envíos',
  Printing: 'Impresión',
  Domicile: 'Domicilio',
  Print: 'Imprimir',
  'Order status': 'Estado de la orden',
  'Printing status': 'Estado de impresión',
  'Shipment id': 'Id de envío',
  Seller: 'Vendedor',
  Sender: 'Remitente',
  'Customer name': 'Nombre del cliente',
  Observation: 'Observación',
  'No description': 'Sin descripción ',
  Product: 'Producto',
  'Label to stick on the package': 'Etiqueta para pegar en el paquete',
  'Information for assembling the package': 'Información para el armado del paquete',
};
