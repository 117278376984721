import React from 'react';
import { Button, Toolbar, Typography, Grid } from '@material-ui/core';
import { AppHeaderMenuProps, itemMenu } from './types';
import { useIsPromotionPath } from '../../../../../utils';

export const AppHeaderMenu = ({ contents, onOpenMenu, onOpenCategories }: AppHeaderMenuProps) => {
  const isPromotionPath = useIsPromotionPath();
  const listMenus: itemMenu[] = [];

  contents.forEach((item) => {
    const obj: itemMenu = {
      name: item.name,
      url: item.url,
      value: item.value,
      priority: 0,
    };

    switch (obj.name) {
      case 'Notebooks y Cloudbooks':
        obj.priority = 1;
        listMenus.push(obj);
        break;
      case 'Tablets':
        obj.priority = 2;
        listMenus.push(obj);
        break;
      case 'Celulares':
        obj.priority = 3;
        listMenus.push(obj);
        break;
      case 'Smart TV':
        obj.priority = 4;
        listMenus.push(obj);
        break;
      case 'PC y Monitores':
        obj.priority = 5;
        listMenus.push(obj);
        break;
      case 'Lavarropas':
        obj.priority = 6;
        listMenus.push(obj);
        break;
      case 'Bicicletas Eléctricas':
        obj.priority = 7;
        listMenus.push(obj);
        break;
      default:
        break;
    }
  });

  listMenus.sort((a, b) => (a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0));

  return (
    <Toolbar variant="dense" disableGutters>
      <Grid container direction="row" justify="space-between">
        <Grid item container>
          {!isPromotionPath && (
            <>
              <Button style={{ textTransform: 'none' }} color="primary" onClick={() => onOpenMenu()} id="allprod-nav">
                <Typography variant="body2">Todos los productos</Typography>
              </Button>
              {listMenus.map((item) => (
                <Button style={{ textTransform: 'none' }} onClick={() => onOpenCategories(item)} id={`${item.name}-nav`}>
                  <Typography variant="body2">{item.name}</Typography>
                </Button>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );
};
