
export const bannerStyles = (background,width) =>
    ({
            containerColumn: {
                display:'flex',
                flexFlow:'row wrap',
                justifyContent:'center',
                alignItems:'center',
                width:'100%',
                height:'80%',
                minHeight:'400px',
                margin:0,
                // border: '1px solid yellow',
                backgroundColor: background,
            },
            containerRow:{
                padding: '20px',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'flex-end',
                width:'100%',
                height:'100%',
                minHeight:'400px',
                margin:0,
                // border: '1px solid red',
                backgroundColor: background,
            },
            boxLeft:{
                display: 'flex',
                flexDirection: 'column',
                alignItems: width > 970 ? 'flex-start' : 'center',
                justifyContent: width > 970 ? 'flex-start' : 'center',
                width: '100%',
                maxWidth: '500px',
                minWidth: '500px',
                height: '100%',
                margin: 0,
                // border: '1px solid black',
                backgroundColor:background,
            },
            boxRight:{
                display: 'flex',
                flexDirection: 'column',
                alignItems: width > 970 ? 'flex-end' : 'center',
                justifyContent: width > 970 ? 'flex-end' : 'center',
                width: '100%',
                maxWidth: '400px',
                minWidth: '400px',
                height: '100%',
                // border: '1px solid red',
                backgroundColor:background,
            },
            boxCenter:{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                margin: 0,
                //  border: '1px solid red',
                backgroundColor:background,
            },
            background: {
                width: 'inherit',
                height: 'inherit',
                backgroundColor:background,
            },
            top: {
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'inherit',
                height: '10%',
                maxHeight:'170px',
                minHeight:'100px',
                // border: '1px solid green'
            },
            formContainer: {
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                paddingLeft:'30px',
                paddingRight:'50px',
                backgroundColor:'#FFFFFF',
                width:'70%',
                height: '50%',
                maxHeight:'400px',
                // minHeight:'250px',
                borderRadius:'15px',
                // border: '1px solid green'
            },
            container: {
                display: 'flex',
                justifyContent: 'flex-start',
                marginBottom: '15px',
                width: '300px'
            },
            textField: {
                margin:'10px',
                width: '100%'
            },
            text: {
                fontSize: '20px',
                lineHeight: '1.2',
                fontWeight: 600,
                color:'#FFFFFF',
            }
        }
    )
