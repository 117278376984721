import React from 'react';
import { Box, Grid, Typography, Container, useMediaQuery, Theme, Hidden, Paper, Link, Divider } from '@material-ui/core';
import { OptionListProps } from 'src/commons/ui/view/OptionList/OptionList';
import { Padding } from 'src/commons/components/Padding';
import { SPACING } from 'src/commons';
import { appFooterStyles as useStyles } from 'src/app/views/styles';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import { Platense } from '../../../../assets/images';
import caceLogo from '../../../../assets/images/CACE originales 3.png';
import dataFiscal from '../../../../assets/images/DATAWEB 2.svg';
import { ecommerceConfig } from '../../../../config/ecommerce';
import { AppFooterSubscription } from './components';

export interface AppFooterProps {
  title: string;
  email: string;
  onChangeEmail: () => void;
  onSubcriber: () => void;
  optionLists: Array<OptionListProps>;
}

export const AppFooter = ({ optionLists }: AppFooterProps) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down(769));
  const isUp767 = useMediaQuery<Theme>((theme) => theme.breakpoints.up(767));
  const is540 = useMediaQuery<Theme>((theme) => theme.breakpoints.up(539));
  const Wrapper = isDownSm ? React.Fragment : Padding;
  const { mainLogo: MainLogo } = ecommerceConfig.ecommerceLogo;

  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <Box mt="-5px">
        <Paper elevation={3} square>
          <AppFooterSubscription />
          <Divider component="div" />
          <Container maxWidth="lg">
            <Box minHeight={250}>
              <Wrapper>
                <Grid container spacing={SPACING.COMMON} className={classes.container}>
                  <Hidden smDown>
                    <Grid item sm={12} md={12} xl={3} lg={3}>
                      <MainLogo />
                    </Grid>
                  </Hidden>
                  {optionLists.map((option) => (
                    <Grid key={option.title} item xs={12} sm={isUp767 ? 4 : is540 ? 6 : 12} md={4} lg={3}>
                      {ecommerceConfig.general.nameMp === 'enova' && option.title === 'Contacto' && <img src={Platense} alt="san lorenzo" />}
                      <Typography variant="h6">{option.title}</Typography>
                      {option.items.map((item) => (
                        <Link
                          key={item.description}
                          href={item.url}
                          target={item.blank && item.blank}
                          underline={item.url ? 'hover' : 'none'}
                          color="inherit"
                        >
                          {item.description === 'Facebook' ? (
                            <FacebookIcon className={classes.socialMediaIcons} />
                          ) : item.description === 'Instagram' ? (
                            <InstagramIcon className={classes.socialMediaIcons} />
                          ) : (
                            <Typography variant="body1">{item.description}</Typography>
                          )}
                        </Link>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Wrapper>
            </Box>
          </Container>
          <Grid className={classes.bottom} container>
            <Container maxWidth="lg">
              <Grid container direction="row" justify="space-between" alignItems="center" className={classes.fullHeight}>
                <Grid>
                  <Typography variant="body2" color="textPrimary">
                    Copyright © Enova Store 2021{' '}
                    <Typography variant="body2" color="textSecondary">
                      Built with JetCommerce by{' '}
                      <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://www.phinxlab.com/">
                        Phinxlab
                      </a>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid spacing={1} direction="row" item container className={classes.autoWidth} alignItems="center">
                  <Grid>
                    <img height={65} src={caceLogo} alt="cace logo" />
                  </Grid>
                  <Grid>
                    <a href="http://qr.afip.gob.ar/?qr=b0Lr-aQ_n6jfULRC_e-HnA,,">
                      <img src={dataFiscal} alt="fiscal data qr" />
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};
