import { useEffect, useState, useMemo } from 'react';
import { AnyObject } from '../../../../commons';
import { useFormContext } from '../../../../lib/templates';
import { checkoutAllValues } from '../initialValues';

export const useFormGenderTemp = () => {
  const [showGender, setShowGender] = useState<boolean>();
  const { values, form } = useFormContext<AnyObject>();
  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  useEffect(() => {
    const isResponsable = valuesForm.isResponsable === 'true';
    setShowGender(isResponsable);
  }, [valuesForm.isResponsable]);

  useEffect(() => {
    if (!showGender) {
      form.change('personalInfo.gender', values.personalInfo?.gender);
    } else {
      form.change('personalInfo.gender', 'M');
    }
  }, [form, showGender, values.personalInfo?.gender]);
  return { showGender };
};
