import { Publication } from './publication';
import { FooterInfo } from './FooterInfo';

export interface ConfigValues {
  imagen: { small: string; normal: string };
  redirectTo?: string;
  where?: any;
  icon?: string | null;
}

export interface ConfigValuesImages {
  images: Array<{ url: string; relevance: number; card_emiter_id: number; enable: boolean }>;
  iconsUrl?: Array<FooterInfo>;
}
// INFO : main_price or alter_price if true show one installment payment, else show cuotes payment
type Amounts = 'original_amount' | 'amount';
export interface ConfigValuesPublication {
  showPrice: { main_price: boolean; alter_price: boolean; main_amount: Amounts; alter_amount: Amounts };
  searchable: boolean;
  bank_config: string;
  singlePay: boolean;
  card_id: string;
}

export const defaultConfigValues: ConfigValuesPublication = {
  showPrice: { main_price: false, alter_price: true, main_amount: 'amount', alter_amount: 'amount' },
  searchable: true,
  bank_config: 'default',
  singlePay: false,
  card_id: '0',
};

export type Category = {
  id: string;
  name: string;
  config_values: ConfigValues;
  publications: Publication[];
  relevance?: number | string;
};

export interface CategoryPromo extends Category {
  value: number;
}
